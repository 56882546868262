import React, { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { colorBackgroundLayoutMain } from '@amzn/awsui-design-tokens/polaris';
import Container from '@amzn/awsui-components-react/polaris/container';
import { Navigate } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import ReactLoading from 'react-loading';
import { getLoggedInUser, handleSignIn } from '../utils/authHelper';

export const TopsDashboardPage = () => {
  // console.log('rendering DummyDashboardPage');
  const userContext = useContext(UserContext);
  const jwtToken =
    userContext.gandalfUserData?.signInUserSession.idToken.jwtToken;
  const [dashboardLoaded, setDashboardLoaded] = useState(false);

  // if user not logged in, make sign in.
  getLoggedInUser().catch(() => {
    handleSignIn();
  });

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: colorBackgroundLayoutMain,
      }}
    >
      {/* wait for userContext to load & check for authorization */}
      {userContext.isLoading === false &&
        userContext.cchAuthorization !== 'tops' && (
          <Navigate to="/" replace={true} />
        )}
      <div style={{ width: '90%', margin: '2rem auto' }}>
        <Container
          disableContentPaddings
          media={{
            content: (
              <div style={{ minHeight: '400px' }}>
                {window.location.hostname === 'localhost' ? (
                  <div>localhost, don't load dashboard</div>
                ) : (
                  <>
                    {/* start loading spinner */}
                    {!dashboardLoaded && (
                      <div style={{ padding: '2rem' }}>
                        <ReactLoading color="black" />
                      </div>
                    )}
                    {/* end loading spinner */}
                    <Dashboard
                      dashboardUserType="tops"
                      dashboardId="cch_sso_main_dashboard"
                      setDashboardLoaded={setDashboardLoaded}
                      jwtToken={jwtToken}
                    ></Dashboard>
                  </>
                )}
              </div>
            ),
          }}
        ></Container>
      </div>
    </div>
  );
};
